import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import TableComponent from "components/partials/table-component/table-component";
import { tableSettings } from "./meeting-summaries-tablesettings";
import Heading from "components/partials/heading/heading";
import Icons from "assets/icons";
import SummarizeModal from "../new-summary/summarize-modal";
import CustomButton from "components/partials/custom-button/custom-button";
import { MeetingDetailContext } from "context/MeetingDetailContext";

const MeetingSummaries = ({ summaries, handleViewSummaryClick, activeIndex }) => {
  const [sortingParams, setSortingParams] = useState([{ id: "summaryDate", desc: true }]);
  const columns = tableSettings(handleViewSummaryClick, activeIndex);
  const [isSummarizeModalOpen, setIsSummarizeModalOpen] = useState(false);
  const { meetingId } = useParams();
  const { summarizeMeeting, setSummary } = useContext(MeetingDetailContext);


  const sourceOptions = [
    { label: "Goals", value: "GOALS" },
    { label: "General", value: "GENERAL" },
    { label: "MEDDPICC", value: "MEDDPICC" },
  ];

  const handleCloseModal = () => {
    setIsSummarizeModalOpen(false);
  };

  const handleResummarizeMeeting = () => {
    setIsSummarizeModalOpen(true);
  };

  const handleSummarizeSubmit = (selectedValue) => {
    setSummary(null);
    summarizeMeeting(meetingId, selectedValue);
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between">
        <Heading variant="h1" customClass="font-bold pb-1">
          Meeting Summaries
        </Heading>
        <CustomButton
          variant="primary"
          size="md"
          iconBefore={<Icons.Plus aria-label="Resummarize" height="100%" width="15" />}
          onClick={handleResummarizeMeeting}
        >
          Resummarize
        </CustomButton>
      </div>

      <TableComponent columns={columns} data={summaries} sortingParams={sortingParams} setSortingParams={setSortingParams} pagination={false} />
      {isSummarizeModalOpen && <SummarizeModal onClose={handleCloseModal} onSubmit={handleSummarizeSubmit} sourceOptions={sourceOptions} />}
    </div>
  );
};

export default MeetingSummaries;

import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { tableSettings } from "./new-summary-gong-tablesettings";
import TableComponent from "components/partials/table-component/table-component";
import Heading from "components/partials/heading/heading";
import SummarizeModal from "../summarize-modal";
import Icons from "assets/icons";
import { ToastContainer } from "react-toastify";
import { MeetingContext } from "context/MeetingContext";
import { MeetingDetailContext } from "context/MeetingDetailContext";
import CustomButton from "components/partials/custom-button/custom-button";

const NewSummaryGong = () => {
  const [isSummarizeModalOpen, setIsSummarizeModalOpen] = useState(false);
  const [currentRowData, setCurrentRowData] = useState(null);
  const {
    recentMeetings,
    pageSize,
    totalRecords,
    setAmount,
    pageIndex,
    setPageIndex,
    sortingParams,
    setSortingParams,
    isMeetingsLoading,
    fetchMeetings,
    setRecentMeetings,
  } = useContext(MeetingContext);
  const { summarizeMeeting, setSummary, resetMeetingDetails } = useContext(MeetingDetailContext);

  const sourceOptions = [
    { label: "Goals", value: "GOALS" },
    { label: "General", value: "GENERAL" },
    { label: "MEDDPICC", value: "MEDDPICC" },
  ];

  const navigate = useNavigate();

  const handleSummarizeClick = (rowData) => {
    setCurrentRowData(rowData);
    setIsSummarizeModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsSummarizeModalOpen(false);
    setCurrentRowData(null);
  };

  const handleSummarizeSubmit = (selectedValue) => {
    setSummary(null);
    summarizeMeeting(currentRowData.id, selectedValue);
    navigate(`/intelligence/meetings/${currentRowData.id}/details`);
  };

  useEffect(() => {
    resetMeetingDetails();
    fetchMeetings(true);
  }, []);

  const handleBackClick = () => {
    setRecentMeetings([]);
    navigate("/intelligence/meetings");
  };

  // Update tableSettings to accept handleSummarizeClick
  const columns = tableSettings(handleSummarizeClick);

  const handleSearch = (term) => {
    if (!isMeetingsLoading) {
      fetchMeetings(true, term);
    }
  };

  return (
    <>
      <ToastContainer style={{ width: "100%", fontSize: "16px" }} progressStyle={{ backgroundColor: "#b6de3a" }} />
      <div className="mb-9 flex shrink grow basis-0 flex-col gap-2">
        <div className="mb-5 flex flex-col items-start">
          <CustomButton
            variant="secondaryWithoutBorder"
            size="smSquare"
            iconBefore={<Icons.Arrow customClass="rotate-180" aria-label="Back" height="100%" width="14" />}
            onClick={() => handleBackClick()}
          >
            Back
          </CustomButton>
          <Heading variant="h1" customClass="font-bold">
            Recent Meetings
          </Heading>
        </div>
        <TableComponent
          columns={columns}
          data={recentMeetings}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPagination={(page) => {
            setPageIndex(page);
          }}
          setAmount={setAmount}
          sortingParams={sortingParams}
          setSortingParams={setSortingParams}
          totalRecords={totalRecords}
          customHeight={recentMeetings.length === pageSize && "shrink grow basis-0"}
          enableSearch={true}
          onSearchChange={handleSearch}
          searchLabel="Search for Meeting or Partner"
          searchPlacholder="Search for meeting or partner..."
          isLoading={isMeetingsLoading}
        />
        {isSummarizeModalOpen && <SummarizeModal onClose={handleCloseModal} onSubmit={handleSummarizeSubmit} sourceOptions={sourceOptions} />}
      </div>
    </>
  );
};

export default NewSummaryGong;
